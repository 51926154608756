import { FC, useEffect, useState } from "react";
import useApi from "../lib/hooks/useApi";
import { getDeviceApiClient as apiClient } from "../lib/api-helper/apiHelper";
import Authenticated from "../components/authenticated";
import { Constants } from "../constants";

const DeviceLinks: FC = () => {
  const [device, setDevice] = useState("");
  const [yourDevices, setYourDevices] = useState([]);
  const [deleteDevice, setDeleteDevice] = useState("");
  const [{ status, error, statusCode }, createDeviceLink, refreshCreate] = useApi(apiClient, "", device, "", "POST");

  const [{ status: deleteStatus, statusCode: deleteCode }, removeDeviceLink, refreshDelete] = useApi(
    apiClient,
    "",
    deleteDevice,
    "",
    "POST"
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ data: deviceData, status: deviceLoading }, _getDevices, refresh] = useApi(
    apiClient,
    "deviceGet",
    "",
    "",
    "GET"
  );

  useEffect(() => {
    deviceLoading === "fetched" && setYourDevices(deviceData.devices);
  }, [deviceData, deviceLoading]);

  const linkDevice = () => {
    if (status === "fetched" || status === "error") {
      refreshCreate();
    } else {
      createDeviceLink("deviceIdLinkPost");
    }
  };

  const unlinkDevice = (item) => {
    setDeleteDevice(item);
    if (deleteStatus === "fetched") {
      refreshDelete();
    } else {
      removeDeviceLink("deviceIdLinkDelete");
    }
  };

  useEffect(() => {
    if (statusCode === 201) {
      refresh();
      setDevice("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusCode]);

  useEffect(() => {
    if (deleteCode === 200) {
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteCode]);

  return (
    <Authenticated>
      <div className="max-w-3xl px-4 py-12 mx-auto sm:px-6 lg:py-16 lg:px-8">
        <h1 className="space-y-8 text-3xl font-extrabold text-gray-900 sm:text-4xl">Add a new device</h1>
        <p className="mt-3 text-lg text-gray-500">
          {Constants.ADD_DEVICE_DESC}
          <a href={`${process.env.REACT_APP_DEVICE_MANAGER_URL}`} className="font-bold text-blue-500">
            {Constants.DEVICE_MANAGER}
          </a>
        </p>

        <div className="my-6 space-y-8 divide-y divide-gray-200">
          <div className="pt-6 md:grid md:grid-cols-12 md:gap-8">
            <dt className="text-base font-medium text-gray-900 md:col-span-5">
              <input
                className="w-full p-2 border border-gray-700 rounded sm:text-sm"
                value={device}
                onChange={(e) => setDevice(e.target.value)}
                placeholder="Enter a device id"
              />
              {/* @ts-ignore */}
              {error && <p className="text-small text-pol-red">{error}</p>}
            </dt>
            <dd className="right-0 mt-2 md:mt-0 md:col-span-7">
              <button
                onClick={linkDevice}
                className="flex float-right px-4 py-2 text-sm font-medium text-white bg-gray-700 border border-transparent rounded-md shadow-sm hover:bg-gray-800"
              >
                {Constants.LINK_DEVICE}
              </button>
            </dd>
          </div>
        </div>

        <div className="mx-auto divide-y-2 divide-gray-200 ">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">Your Devices</h2>
          {yourDevices !== null &&
            yourDevices.map((item) => (
              <div className="mt-6">
                <dl className="space-y-8 divide-y divide-gray-200">
                  <div className="pt-6 md:grid md:grid-cols-12 md:gap-8">
                    <dt className="text-base font-medium text-gray-900 md:col-span-5">{item.name}</dt>
                    <dd className="right-0 mt-2 md:mt-0 md:col-span-7">
                      <button
                        onClick={() => unlinkDevice(item.id)}
                        className="flex float-right px-4 py-2 text-sm font-medium text-white bg-gray-700 border border-transparent rounded-md shadow-sm hover:bg-gray-800"
                      >
                        {Constants.UNLINK_DEVICE}
                      </button>
                    </dd>
                  </div>
                </dl>
              </div>
            ))}
        </div>
      </div>
    </Authenticated>
  );
};

export default DeviceLinks;
