/* tslint:disable */
/* eslint-disable */
/**
 * SPM Device Management APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.1
 * Contact: tbc@postoffice.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from "./configuration";
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "./base";

/**
 *
 * @export
 * @interface Device
 */
export interface Device {
  /**
   * Device ID
   * @type {string}
   * @memberof Device
   */
  id?: string;
  /**
   * Device Name
   * @type {string}
   * @memberof Device
   */
  name?: string;
  /**
   * Branch Name
   * @type {string}
   * @memberof Device
   */
  branch_id?: string;
  /**
   *
   * @type {string}
   * @memberof Device
   */
  node_id?: string;
  /**
   * Record Creation Date Time
   * @type {string}
   * @memberof Device
   */
  created_at?: string;
}
/**
 *
 * @export
 * @interface DevicesResponse
 */
export interface DevicesResponse {
  /**
   *
   * @type {number}
   * @memberof DevicesResponse
   */
  items?: number;
  /**
   *
   * @type {string}
   * @memberof DevicesResponse
   */
  offset?: string;
  /**
   *
   * @type {Array<Device>}
   * @memberof DevicesResponse
   */
  devices?: Array<Device>;
}
/**
 *
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
  /**
   *
   * @type {string}
   * @memberof ErrorResponse
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ErrorResponse
   */
  message?: string;
}
/**
 *
 * @export
 * @interface RegisterDeviceRequest
 */
export interface RegisterDeviceRequest {
  /**
   * Branch ID
   * @type {string}
   * @memberof RegisterDeviceRequest
   */
  id: string;
  /**
   * Device Name
   * @type {string}
   * @memberof RegisterDeviceRequest
   */
  name: string;
  /**
   * Device Type
   * @type {string}
   * @memberof RegisterDeviceRequest
   */
  type: string;
  /**
   * Branch ID
   * @type {string}
   * @memberof RegisterDeviceRequest
   */
  branch_id: string;
  /**
   * Branch Name
   * @type {string}
   * @memberof RegisterDeviceRequest
   */
  branch_name: string;
  /**
   * Branch Address
   * @type {string}
   * @memberof RegisterDeviceRequest
   */
  branch_address: string;
  /**
   * Branch Postcode
   * @type {string}
   * @memberof RegisterDeviceRequest
   */
  branch_postcode: string;
  /**
   * Branch Org Unit Code
   * @type {string}
   * @memberof RegisterDeviceRequest
   */
  branch_org_unit_code?: string;
  /**
   * Branch Org Unit Code Version
   * @type {string}
   * @memberof RegisterDeviceRequest
   */
  branch_org_unit_code_version?: string;
  /**
   *
   * @type {string}
   * @memberof RegisterDeviceRequest
   */
  node_id: string;
}
/**
 *
 * @export
 * @interface RegisterDeviceResponse
 */
export interface RegisterDeviceResponse {
  /**
   * One Time Pin
   * @type {string}
   * @memberof RegisterDeviceResponse
   */
  otp?: string;
}
/**
 *
 * @export
 * @interface UpdateDeviceOTPRequest
 */
export interface UpdateDeviceOTPRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateDeviceOTPRequest
   */
  id: string;
}
/**
 *
 * @export
 * @interface UpdateDeviceOTPResponse
 */
export interface UpdateDeviceOTPResponse {
  /**
   *
   * @type {string}
   * @memberof UpdateDeviceOTPResponse
   */
  otp?: string;
}
/**
 *
 * @export
 * @interface UpdateDeviceRequest
 */
export interface UpdateDeviceRequest {
  /**
   * Device ID
   * @type {string}
   * @memberof UpdateDeviceRequest
   */
  id: string;
  /**
   * Branch ID
   * @type {string}
   * @memberof UpdateDeviceRequest
   */
  branch_id: string;
  /**
   * Branch Name
   * @type {string}
   * @memberof UpdateDeviceRequest
   */
  branch_name: string;
  /**
   * Branch Address
   * @type {string}
   * @memberof UpdateDeviceRequest
   */
  branch_address: string;
  /**
   * Branch Postcode
   * @type {string}
   * @memberof UpdateDeviceRequest
   */
  branch_postcode: string;
  /**
   * Branch Org Unit Code
   * @type {string}
   * @memberof UpdateDeviceRequest
   */
  branch_org_unit_code?: string;
  /**
   * Branch Org Unit Code Version
   * @type {string}
   * @memberof UpdateDeviceRequest
   */
  branch_org_unit_code_version?: string;
  /**
   * Device Name
   * @type {string}
   * @memberof UpdateDeviceRequest
   */
  name: string;
  /**
   * Node ID
   * @type {string}
   * @memberof UpdateDeviceRequest
   */
  node_id: string;
  /**
   * Device Type
   * @type {string}
   * @memberof UpdateDeviceRequest
   */
  type: string;
}
/**
 *
 * @export
 * @interface UpdateDeviceResponse
 */
export interface UpdateDeviceResponse {
  /**
   *
   * @type {string}
   * @memberof UpdateDeviceResponse
   */
  otp?: string;
}

/**
 * DeviceManagementApi - axios parameter creator
 * @export
 */
export const DeviceManagementApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Obtain a paginated list of devices
     * @summary Get list of devices
     * @param {number} [limit] Total devices to return per request - default is 30
     * @param {string} [offset] Key of last item returned in previous request - used for pagination
     * @param {string} [deviceIds] Device ID\&#39;s to return, doesn\&#39;t work in tandem with pagination, max 100 records returned
     * @param {string} [q] Search term for device-id and device-name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deviceGet: async (
      limit?: number,
      offset?: string,
      deviceIds?: string,
      q?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/device`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication DeviceManager required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (deviceIds !== undefined) {
        localVarQueryParameter["device_ids"] = deviceIds;
      }

      if (q !== undefined) {
        localVarQueryParameter["q"] = q;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * An OTP can only be generated if it has a branch association
     * @summary Regenerate OTP for device
     * @param {UpdateDeviceOTPRequest} updateDeviceOTPRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deviceOtpPut: async (
      updateDeviceOTPRequest: UpdateDeviceOTPRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateDeviceOTPRequest' is not null or undefined
      assertParamExists("deviceOtpPut", "updateDeviceOTPRequest", updateDeviceOTPRequest);
      const localVarPath = `/device/otp`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication DeviceManager required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateDeviceOTPRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Registers a device and optionally associates it to a branch
     * @summary Register the device
     * @param {RegisterDeviceRequest} registerDeviceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devicePost: async (
      registerDeviceRequest: RegisterDeviceRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'registerDeviceRequest' is not null or undefined
      assertParamExists("devicePost", "registerDeviceRequest", registerDeviceRequest);
      const localVarPath = `/device`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication DeviceManager required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(registerDeviceRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update a device
     * @param {UpdateDeviceRequest} updateDeviceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devicePut: async (
      updateDeviceRequest: UpdateDeviceRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateDeviceRequest' is not null or undefined
      assertParamExists("devicePut", "updateDeviceRequest", updateDeviceRequest);
      const localVarPath = `/device`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication DeviceManager required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(updateDeviceRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DeviceManagementApi - functional programming interface
 * @export
 */
export const DeviceManagementApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DeviceManagementApiAxiosParamCreator(configuration);
  return {
    /**
     * Obtain a paginated list of devices
     * @summary Get list of devices
     * @param {number} [limit] Total devices to return per request - default is 30
     * @param {string} [offset] Key of last item returned in previous request - used for pagination
     * @param {string} [deviceIds] Device ID\&#39;s to return, doesn\&#39;t work in tandem with pagination, max 100 records returned
     * @param {string} [q] Search term for device-id and device-name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deviceGet(
      limit?: number,
      offset?: string,
      deviceIds?: string,
      q?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DevicesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deviceGet(limit, offset, deviceIds, q, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * An OTP can only be generated if it has a branch association
     * @summary Regenerate OTP for device
     * @param {UpdateDeviceOTPRequest} updateDeviceOTPRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deviceOtpPut(
      updateDeviceOTPRequest: UpdateDeviceOTPRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateDeviceOTPResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deviceOtpPut(updateDeviceOTPRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Registers a device and optionally associates it to a branch
     * @summary Register the device
     * @param {RegisterDeviceRequest} registerDeviceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async devicePost(
      registerDeviceRequest: RegisterDeviceRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegisterDeviceResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.devicePost(registerDeviceRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update a device
     * @param {UpdateDeviceRequest} updateDeviceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async devicePut(
      updateDeviceRequest: UpdateDeviceRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateDeviceResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.devicePut(updateDeviceRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DeviceManagementApi - factory interface
 * @export
 */
export const DeviceManagementApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = DeviceManagementApiFp(configuration);
  return {
    /**
     * Obtain a paginated list of devices
     * @summary Get list of devices
     * @param {number} [limit] Total devices to return per request - default is 30
     * @param {string} [offset] Key of last item returned in previous request - used for pagination
     * @param {string} [deviceIds] Device ID\&#39;s to return, doesn\&#39;t work in tandem with pagination, max 100 records returned
     * @param {string} [q] Search term for device-id and device-name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deviceGet(
      limit?: number,
      offset?: string,
      deviceIds?: string,
      q?: string,
      options?: any
    ): AxiosPromise<DevicesResponse> {
      return localVarFp.deviceGet(limit, offset, deviceIds, q, options).then((request) => request(axios, basePath));
    },
    /**
     * An OTP can only be generated if it has a branch association
     * @summary Regenerate OTP for device
     * @param {UpdateDeviceOTPRequest} updateDeviceOTPRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deviceOtpPut(updateDeviceOTPRequest: UpdateDeviceOTPRequest, options?: any): AxiosPromise<UpdateDeviceOTPResponse> {
      return localVarFp.deviceOtpPut(updateDeviceOTPRequest, options).then((request) => request(axios, basePath));
    },
    /**
     * Registers a device and optionally associates it to a branch
     * @summary Register the device
     * @param {RegisterDeviceRequest} registerDeviceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devicePost(registerDeviceRequest: RegisterDeviceRequest, options?: any): AxiosPromise<RegisterDeviceResponse> {
      return localVarFp.devicePost(registerDeviceRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update a device
     * @param {UpdateDeviceRequest} updateDeviceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devicePut(updateDeviceRequest: UpdateDeviceRequest, options?: any): AxiosPromise<UpdateDeviceResponse> {
      return localVarFp.devicePut(updateDeviceRequest, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * DeviceManagementApi - object-oriented interface
 * @export
 * @class DeviceManagementApi
 * @extends {BaseAPI}
 */
export class DeviceManagementApi extends BaseAPI {
  /**
   * Obtain a paginated list of devices
   * @summary Get list of devices
   * @param {number} [limit] Total devices to return per request - default is 30
   * @param {string} [offset] Key of last item returned in previous request - used for pagination
   * @param {string} [deviceIds] Device ID\&#39;s to return, doesn\&#39;t work in tandem with pagination, max 100 records returned
   * @param {string} [q] Search term for device-id and device-name
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceManagementApi
   */
  public deviceGet(limit?: number, offset?: string, deviceIds?: string, q?: string, options?: AxiosRequestConfig) {
    return DeviceManagementApiFp(this.configuration)
      .deviceGet(limit, offset, deviceIds, q, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * An OTP can only be generated if it has a branch association
   * @summary Regenerate OTP for device
   * @param {UpdateDeviceOTPRequest} updateDeviceOTPRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceManagementApi
   */
  public deviceOtpPut(updateDeviceOTPRequest: UpdateDeviceOTPRequest, options?: AxiosRequestConfig) {
    return DeviceManagementApiFp(this.configuration)
      .deviceOtpPut(updateDeviceOTPRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Registers a device and optionally associates it to a branch
   * @summary Register the device
   * @param {RegisterDeviceRequest} registerDeviceRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceManagementApi
   */
  public devicePost(registerDeviceRequest: RegisterDeviceRequest, options?: AxiosRequestConfig) {
    return DeviceManagementApiFp(this.configuration)
      .devicePost(registerDeviceRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update a device
   * @param {UpdateDeviceRequest} updateDeviceRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceManagementApi
   */
  public devicePut(updateDeviceRequest: UpdateDeviceRequest, options?: AxiosRequestConfig) {
    return DeviceManagementApiFp(this.configuration)
      .devicePut(updateDeviceRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SimulatorDeviceManagementApi - axios parameter creator
 * @export
 */
export const SimulatorDeviceManagementApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Obtain a paginated list of devices
     * @summary Get list of devices
     * @param {number} [limit] Total devices to return per request - default is 30
     * @param {string} [offset] Key of last item returned in previous request - used for pagination
     * @param {string} [deviceIds] Device ID\&#39;s to return, doesn\&#39;t work in tandem with pagination, max 100 records returned
     * @param {string} [q] Search term for device-id and device-name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    simulatorDeviceGet: async (
      limit?: number,
      offset?: string,
      deviceIds?: string,
      q?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/simulator/device`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Simulator required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (deviceIds !== undefined) {
        localVarQueryParameter["device_ids"] = deviceIds;
      }

      if (q !== undefined) {
        localVarQueryParameter["q"] = q;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * An OTP can only be generated if it has a branch association
     * @summary Regenerate OTP for device
     * @param {UpdateDeviceOTPRequest} updateDeviceOTPRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    simulatorDeviceOtpPut: async (
      updateDeviceOTPRequest: UpdateDeviceOTPRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateDeviceOTPRequest' is not null or undefined
      assertParamExists("simulatorDeviceOtpPut", "updateDeviceOTPRequest", updateDeviceOTPRequest);
      const localVarPath = `/simulator/device/otp`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Simulator required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateDeviceOTPRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SimulatorDeviceManagementApi - functional programming interface
 * @export
 */
export const SimulatorDeviceManagementApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SimulatorDeviceManagementApiAxiosParamCreator(configuration);
  return {
    /**
     * Obtain a paginated list of devices
     * @summary Get list of devices
     * @param {number} [limit] Total devices to return per request - default is 30
     * @param {string} [offset] Key of last item returned in previous request - used for pagination
     * @param {string} [deviceIds] Device ID\&#39;s to return, doesn\&#39;t work in tandem with pagination, max 100 records returned
     * @param {string} [q] Search term for device-id and device-name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async simulatorDeviceGet(
      limit?: number,
      offset?: string,
      deviceIds?: string,
      q?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DevicesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.simulatorDeviceGet(
        limit,
        offset,
        deviceIds,
        q,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * An OTP can only be generated if it has a branch association
     * @summary Regenerate OTP for device
     * @param {UpdateDeviceOTPRequest} updateDeviceOTPRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async simulatorDeviceOtpPut(
      updateDeviceOTPRequest: UpdateDeviceOTPRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateDeviceOTPResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.simulatorDeviceOtpPut(updateDeviceOTPRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * SimulatorDeviceManagementApi - factory interface
 * @export
 */
export const SimulatorDeviceManagementApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = SimulatorDeviceManagementApiFp(configuration);
  return {
    /**
     * Obtain a paginated list of devices
     * @summary Get list of devices
     * @param {number} [limit] Total devices to return per request - default is 30
     * @param {string} [offset] Key of last item returned in previous request - used for pagination
     * @param {string} [deviceIds] Device ID\&#39;s to return, doesn\&#39;t work in tandem with pagination, max 100 records returned
     * @param {string} [q] Search term for device-id and device-name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    simulatorDeviceGet(
      limit?: number,
      offset?: string,
      deviceIds?: string,
      q?: string,
      options?: any
    ): AxiosPromise<DevicesResponse> {
      return localVarFp
        .simulatorDeviceGet(limit, offset, deviceIds, q, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * An OTP can only be generated if it has a branch association
     * @summary Regenerate OTP for device
     * @param {UpdateDeviceOTPRequest} updateDeviceOTPRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    simulatorDeviceOtpPut(
      updateDeviceOTPRequest: UpdateDeviceOTPRequest,
      options?: any
    ): AxiosPromise<UpdateDeviceOTPResponse> {
      return localVarFp
        .simulatorDeviceOtpPut(updateDeviceOTPRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * SimulatorDeviceManagementApi - object-oriented interface
 * @export
 * @class SimulatorDeviceManagementApi
 * @extends {BaseAPI}
 */
export class SimulatorDeviceManagementApi extends BaseAPI {
  /**
   * Obtain a paginated list of devices
   * @summary Get list of devices
   * @param {number} [limit] Total devices to return per request - default is 30
   * @param {string} [offset] Key of last item returned in previous request - used for pagination
   * @param {string} [deviceIds] Device ID\&#39;s to return, doesn\&#39;t work in tandem with pagination, max 100 records returned
   * @param {string} [q] Search term for device-id and device-name
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SimulatorDeviceManagementApi
   */
  public simulatorDeviceGet(
    limit?: number,
    offset?: string,
    deviceIds?: string,
    q?: string,
    options?: AxiosRequestConfig
  ) {
    return SimulatorDeviceManagementApiFp(this.configuration)
      .simulatorDeviceGet(limit, offset, deviceIds, q, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * An OTP can only be generated if it has a branch association
   * @summary Regenerate OTP for device
   * @param {UpdateDeviceOTPRequest} updateDeviceOTPRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SimulatorDeviceManagementApi
   */
  public simulatorDeviceOtpPut(updateDeviceOTPRequest: UpdateDeviceOTPRequest, options?: AxiosRequestConfig) {
    return SimulatorDeviceManagementApiFp(this.configuration)
      .simulatorDeviceOtpPut(updateDeviceOTPRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
