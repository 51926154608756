import { Context, createContext, useContext, useEffect, useState } from "react";
import { Device } from "../../../openapi/device-api/api";
import { useGlobalState } from "../../GlobalState";

export interface DeviceAttributes {
  nodeID: number;
  deviceID: string;
  deviceType: string;
  deviceName: string;
  branchID: string;
}

const getUserAttributes = async (device: Device): Promise<DeviceAttributes> => {
  return {
    branchID: device.branch_id,
    deviceID: device.id,
    deviceName: device.name,
    deviceType: device.type,
    nodeID: parseInt(device.node_id, 10),
  };
};

const DeviceContext: Context<DeviceAttributes> = createContext<DeviceAttributes>({
  branchID: "",
  deviceID: "",
  deviceType: "",
  deviceName: "",
  nodeID: 0,
});

export function useDeviceAttributes() {
  const context = useContext(DeviceContext);

  if (!context) {
    throw new Error("useDeviceAttributes must be used within a DeviceAttributesProvider");
  }
  return context;
}

export function DeviceAttributesProvider({ children }: { children: JSX.Element }): JSX.Element {
  const [userAttributes, setUserAttributes] = useState<DeviceAttributes>({
    branchID: "",
    deviceID: "",
    deviceType: "",
    deviceName: "",
    nodeID: 0,
  });

  const { selectedDevice } = useGlobalState();

  useEffect(() => {
    async function run() {
      if (selectedDevice) {
        const attrs = await getUserAttributes(selectedDevice);
        setUserAttributes(attrs);
      }
    }
    run();
  }, [selectedDevice]);

  return <DeviceContext.Provider value={userAttributes}>{children}</DeviceContext.Provider>;
}
