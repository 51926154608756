import React from "react";
import { useDeviceAttributes } from "./contexts/DeviceAttributes";
import {
  BasketStateEnum,
  Configuration,
  EntryCore,
  Fulfilment,
  TransactionsApi,
  TransactionsApiInterface,
} from "../../openapi/transaction-api-v2";
import { useGlobalState } from "../GlobalState";

function basketId(branchID: string, nodeId: number) {
  return (seqNumber: number) => `${branchID}-${nodeId}-${seqNumber}`;
}

function createBasket(branchId: string, nodeId: number, client?: TransactionsApiInterface) {
  return async () => {
    const lastId = await client?.getLastSeqNumber(branchId, nodeId);
    if (!lastId) {
      throw new Error("Last sequence number not set");
    }

    const basketIdN = basketId(branchId, nodeId)(lastId.data.lastSeqNumber + 1);
    const resp = await client?.createBasket({
      basketID: basketIdN
    });

    return {
      basketId: basketIdN,
      response: resp,
    };
  };
}

function getBasket(client?: TransactionsApiInterface) {
  return async (basketID: string) => await client?.getBasket(basketID);
}

function getLastSeqNumber(branchId: string, nodeId: number, client?: TransactionsApiInterface) {
  return async () => await client?.getLastSeqNumber(branchId, nodeId);
}

function getLastBasketID(branchId: string, nodeId: number, client?: TransactionsApiInterface) {
  return async () => {
    const lastId = await client?.getLastSeqNumber(branchId, nodeId);
    if (!lastId) {
      throw new Error("Last sequence number not set");
    }

    return basketId(branchId, nodeId)(lastId.data.lastSeqNumber);
  };
}

function getLastBasket(branchID: string, nodeID: number, client?: TransactionsApiInterface) {
  return async () => await client?.getLastBasket(branchID, nodeID);
}

function closeBasket(branchId: string, nodeId: number, client?: TransactionsApiInterface) {
  return async (seqNumber: number, numberOfEntries: number) => {
    const id = basketId(branchId, nodeId)(seqNumber);
    return client?.closeOrModifyBasket({
      basketID: id,
      basketState: BasketStateEnum.Bkc,
      NumberOfEntries: numberOfEntries,
    });
  };
}

function createEntry(client?: TransactionsApiInterface) {
  return async (basketID: string, transactionData: EntryCore) => {
    return client?.createBasketEntry(basketID, transactionData);
  };
}

function updateBasketEntryTokens(client?: TransactionsApiInterface) {
  return async (basketId: string, entry: string, fulfillment?: Fulfilment) => {
    return client?.updateBasketEntryFulfilment(basketId, entry, fulfillment);
  };
}

export const useTransactionAPI = (serverRoot: string) => {
  const [client, setClient] = React.useState<TransactionsApiInterface>();
  const attrs = useDeviceAttributes();
  const { tokens, selectedDevice } = useGlobalState();

  const basePath = serverRoot;

  React.useEffect(() => {
    async function build() {
      const client = new TransactionsApi(
        new Configuration({
          basePath: basePath,
          accessToken: tokens.idToken,
        })
      );

      setClient(client);
    }
    build();
  }, [basePath, attrs, tokens.idToken, selectedDevice]);

  const { branchID, nodeID } = attrs;

  /**
   * All we're doing is wrapping up the various API functions to be easier to use in React by hiding the
   * creation of the transaction API client away from the caller
   */
  return React.useMemo(
    () => ({
      createBasket: createBasket(branchID, nodeID, client),
      getBasket: getBasket(client),
      getLastSeqNumber: getLastSeqNumber(branchID, nodeID, client),
      lastBasketID: getLastBasketID(branchID, nodeID, client),
      closeBasket: closeBasket(branchID, nodeID, client),
      createEntry: createEntry(client),
      getBasketId: basketId(branchID, nodeID),
      getLastBasket: getLastBasket(branchID, nodeID, client),
      updateBasketEntryTokens: updateBasketEntryTokens(client),
      client,
    }),
    [branchID, nodeID, client]
  );
};
