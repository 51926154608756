import React, { FC, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { awsSession } from "../../lib/AWS";

const Authenticated: FC = (props) => {
  const [authenticated, setAuthenticated] = useState<boolean>(true);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const checkSession = async () => {
      try {
        await awsSession();
        setAuthenticated(true);
      } catch (err) {
        setAuthenticated(false);
      }
    };

    checkSession();
  });

  if (authenticated) {
    return props.children as React.ReactElement;
  }

  history.replace(`/login?return=${escape(location.pathname)}`);
  return null;
};

export default Authenticated;
