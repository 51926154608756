import { Context, createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from "react";
import { Device } from "../openapi/device-api/api";
import useDeviceAPI from "./hooks/useDeviceApi";
import { DeviceConfig } from "./models";
import jwt_decode from "jwt-decode";
import axios, { AxiosError } from "axios";

type Tokens = {
  accessToken?: string;
  idToken?: string;
  refreshToken?: string;
};

type GlobalState = {
  deviceConfig: DeviceConfig;
  devices: Device[];
  tokens: Tokens;
  setDeviceConfig: Dispatch<SetStateAction<DeviceConfig>>;
  selectedDevice: Device;
  setSelectedDevice: Dispatch<SetStateAction<Device>>;
  sandboxParams: string;
  setSandboxParams: Dispatch<SetStateAction<string>>;
  getDevices: () => void;
};

interface AccessToken {
  origin_jti: string;
  sub: string;
  event_id: string;
  scope: string;
  auth_time: string;
  iss: string;
  exp: number;
  iat: number;
  jti: number;
  client_id: string;
  username: string;
}

interface AuthenticationResult {
  AccessToken: string;
  ExpiresIn: number;
  IdToken: string;
  TokenType: string;
}

const GlobalStateContext: Context<GlobalState> = createContext<GlobalState>({
  deviceConfig: {
    deviceId: null,
    terminalId: null,
  },
  devices: null,
  tokens: {},
  setDeviceConfig: () => {},
  selectedDevice: undefined,
  setSelectedDevice: () => {},
  setSandboxParams: () => {},
  sandboxParams: null,
  getDevices: () => {},
});

export const useGlobalState = () => {
  const ctx = useContext(GlobalStateContext);

  if (!ctx) {
    throw new Error("useGlobalState must be used within a GlobalStateProvider component");
  }

  return ctx;
};

const isTokenExpired = (refreshTokenDate: number): boolean => {
  const todateDateInSecs: number = Math.round(Date.now() / 1000);
  return todateDateInSecs > refreshTokenDate;
};

export function GlobalStateProvider({ children }: { children: ReactNode }): JSX.Element {
  const [deviceConfig, setDeviceConfig] = useState<DeviceConfig>({
    deviceId: null,
    terminalId: null,
  });
  const [devices, setDevices] = useState<Device[]>(null);
  const [sandboxParams, setSandboxParams] = useState<string>(null);

  /**
   * @description The tokens for the selected device
   */
  const [tokens, setTokens] = useState<Tokens>({});
  const [selectedDevice, setSelectedDevice] = useState<Device>(undefined);
  const deviceApi = useDeviceAPI();

  axios.interceptors.response.use(undefined, async (res: AxiosError) => {
    // if we get an auth error then retry after refreshing the token with the new access token
    if (res.response?.status === 401) {
      await refreshAuthToken(tokens.refreshToken);
      return axios.request({
        ...res,
        headers: {
          Authorization: `Bearer ${tokens.accessToken}`,
        },
      });
    }

    return Promise.reject(res);
  });

  async function getDevices() {
    if (!deviceApi) {
      return;
    }

    try {
      const apiDevices = await deviceApi.deviceGet();

      if (apiDevices.status === 200 && apiDevices.data.devices) {
        setDevices(apiDevices.data.devices);
      } else {
        setDevices([]);
      }
    } catch (e) {
      const err = e as Error;
      console.error(`Failed to fetch devices: ${err}`);
    }
  }

  async function refreshAuthToken(refreshToken: string): Promise<AuthenticationResult | undefined> {
    try {
      const authResponse = await axios.post(
        "https://cognito-idp.eu-west-2.amazonaws.com",
        {
          ClientId: process.env.REACT_APP_DEVICES_CLIENT_ID,
          AuthFlow: "REFRESH_TOKEN_AUTH",
          AuthParameters: {
            REFRESH_TOKEN: refreshToken,
          },
        },
        {
          headers: {
            "content-type": " application/x-amz-json-1.1",
            "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
          },
        }
      );

      if (authResponse.status === 200) {
        const response = authResponse.data.AuthenticationResult as AuthenticationResult;
        return response;
      } else {
        return undefined;
      }
    } catch (err) {
      console.error(`Error refreshing token: ${err}`);
      return undefined;
    }
  }

  async function getTokens() {
    if (!selectedDevice) {
      console.error("No device selected for getTokens");
      return;
    }

    try {
      const tokensResponse = await deviceApi.deviceIdTokenGet(selectedDevice.id);

      if (tokensResponse.status === 200 && tokensResponse.data) {
        const { accessToken, idToken, refreshToken } = tokensResponse.data;

        const tokenDetails = jwt_decode<AccessToken>(accessToken);

        console.info(tokenDetails);

        if (isTokenExpired(tokenDetails.exp)) {
          console.error(`Token for device ID: "${selectedDevice.id}" was expired - will refresh`);
          const tokenResult = await refreshAuthToken(refreshToken);

          if (!tokenResult) {
            setTokens({});
            return;
          }

          const res = await deviceApi.deviceIdTokenPut(selectedDevice.id, {
            accessToken: tokenResult.AccessToken,
            idToken: tokenResult.IdToken,
            refreshToken: refreshToken,
          });

          if (res.status !== 200) {
            // if we can't save the tokens then we'll need to get new ones
            // we'll erase the tokens if it fails as the tokens will no longer reflect their actual values after refreshing
            setTokens({});
            await deviceApi.deviceIdTokenDelete(selectedDevice.id);
            return;
          }

          setTokens({
            refreshToken,
            idToken: tokenResult.IdToken,
            accessToken: tokenResult.AccessToken,
          });

          return;
        }

        setTokens({
          accessToken,
          idToken,
          refreshToken,
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    async function run() {
      await getDevices();
    }

    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceApi]);

  useEffect(() => {
    async function run() {
      await getTokens();
    }

    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDevice]);

  useEffect(() => {
    if (!selectedDevice && devices && devices.length > 0) {
      setSelectedDevice(devices[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devices, tokens]);

  return (
    <GlobalStateContext.Provider
      value={{
        deviceConfig,
        setDeviceConfig,
        devices,
        tokens,
        selectedDevice,
        setSelectedDevice,
        setSandboxParams,
        sandboxParams,
        getDevices,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
}
