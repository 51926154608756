import React, { FC, useEffect, useState } from "react";
import CodeEditor from "@uiw/react-textarea-code-editor";
import Handlebars from "handlebars/dist/cjs/handlebars"; //bug workaround in handlbars/webpack-plugin
import htmr from "htmr";
import { I18n } from "i18n-js";
import { Scope } from "i18n-js/typings/typing";
import Authenticated from "../components/authenticated";
import { Constants } from "../constants";

const ReceiptViewer: FC = () => {
  let i18n = new I18n();

  Handlebars.registerHelper("i18n", function (string: Scope) {
    return i18n.t(string);
  });

  const [template, setTemplate] = useState(`<style>
        .cop-receipt { font-family:"courier new"; font-size: 16px; }
        .cop-receipt, .col-left { text-align: left; }
        .cop-receipt, .col-right { text-align: right; }
        .cop-receipt, .col-centre { text-align: center; }
        .cop-receipt.uppercase { text-transform: uppercase; }
     </style>
  <div class="cop-receipt">
     <div class="col-centre">Post Office Ltd.</div>
     <div class="col-centre uppercase">{{ i18n "cop" }}</div>
     <br/>
     <div class="col-left">
        <p>{{ i18n "branch" }} {{branch}}</p>
        <p>{{ i18n "session" }} {{session}}</p>
        <p>{{date}}</p>
     </div>
     <br/>
     <div>
        {{#each lineItems}}
        <div class="col-centre">
           <p>{{service}}</p>
           <p>Reference</p>
           <p><strong>{{trackingRef}}</strong></p>
           <p>{{afterLastDispatch}}</p>
        </div>
        <div class="col-left">
           <p>Address: {{address}}</p>
           <p>Weight: {{weight}}g Price: £{{price}}</p>
        </div>
        <br/>
        {{/each}}
     </div>
     <div class="col-centre">
        <p>{{ i18n "retainNotice" }}</p>
        <p>Please refer to separate terms and conditions</p>
        <p class="uppercase">{{ i18n "notFinancialReceiptNotice" }}</p>
     </div>
  </div>`);
  const [context, setContext] = useState(`{
    "branch":"123456X",
    "session":"99-7654321098",
    "date":"28/01/2022 11:34",
    "lineItems":[
       {
          "service":"Special D by 1",
          "trackingRef":"AA123456789GB",
          "afterLastDispatch":"Accepted before last collection",
          "address":"23, BB8B 8BB, UK",
          "weight":"99,999",
          "price":"86.75"
       },
       {
          "service":"In Track+Sign Large Let",
          "trackingRef":"PF9876543219GB",
          "afterLastDispatch":"Accepted after last collection",
          "address":"4332, Longstreetname, San Francisco, CA54321, USA",
          "weight":"99,999",
          "price":"9.09"
       }
    ]
 }
 `);
  const [language, setLanguage] = useState("en");
  const [html, setHtml] = useState("");
  const [showLanguageSelector, setShowLanguageSelector] = useState(false);

  // default to demo
  const [translations, setTranslations] = useState(`{
    "en": {
      "session": "Session",
      "cop": "Certificate of Posting",
      "branch": "Branch",
      "retainNotice": "Please retain as your proof of posting",
      "notFinancialReceiptNotice": "This is not a financial receipt"
    },
    "cy": {
      "session": "Sesiwn",
      "cop": "Tystysgrif Postio",
      "retainNotice": "Cadwch fel eich prawf postio",
      "notFinancialReceiptNotice": "Nid yw hyn yn dderbynneb ariannol",
      "branch": "Cangen"
    }
  }`);

  const fontFamily = "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace";
  const codeBlockHeight = "800px";
  const codeBlockFontSize = 14;

  const compile = () => {
    if (!template) {
      alert("Please enter template");
      return;
    }

    if (!context) {
      alert("Please enter context variables");
      return;
    }

    if (!translations) {
      alert("Please enter translations");
      return;
    }

    const parsedTranslations = JSON.parse(translations);

    i18n = new I18n(parsedTranslations);
    i18n.locale = language;

    const compiledTemplate = Handlebars.compile(template);

    const contextVars = JSON.parse(context);
    setHtml(compiledTemplate(contextVars));
  };

  useEffect(() => {
    compile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const languageSelect = (language: string) => {
    setLanguage(language);
    setShowLanguageSelector(false);
  };

  return (
    <Authenticated>
      <button onClick={compile} className="w-full py-2 font-bold text-center text-white bg-green-600">
        COMPILE
      </button>
      <div className="flex mb-4">
        <div className="w-2/5 h-screen p-4">
          <h1 className="text-2xl font-bold">{Constants.RECEIPT_TEMPLATE}</h1>
          <div className="mt-7">
            <CodeEditor
              value={template}
              language="handlebars"
              placeholder={Constants.ENTER_HANDLEBARS_TEMPLATE}
              onChange={(evn) => setTemplate(evn.target.value)}
              padding={15}
              style={{
                fontSize: codeBlockFontSize,
                backgroundColor: "#ffffff",
                fontFamily,
              }}
            />
          </div>
        </div>
        <div className="w-1/4 h-screen p-4">
          <h1 className="text-2xl font-bold">{Constants.CONTEXT}</h1>
          <div className="mt-7">
            <CodeEditor
              value={context}
              language="js"
              placeholder={Constants.ENTER_CONTEXT}
              onChange={(evn) => setContext(evn.target.value)}
              padding={15}
              style={{
                fontSize: codeBlockFontSize,
                backgroundColor: "#ffffff",
                fontFamily,
              }}
            />
          </div>
        </div>
        <div className="w-1/5 h-screen p-4">
          <h1 className="text-2xl font-bold">Translations</h1>
          <div className="mt-7">
            <CodeEditor
              value={translations}
              language="js"
              placeholder={Constants.ENTER_TRANSLATIONS}
              onChange={(evn) => setTranslations(evn.target.value)}
              padding={15}
              style={{
                fontSize: codeBlockFontSize,
                backgroundColor: "#ffffff",
                fontFamily,
              }}
            />
          </div>
        </div>
        <div style={{ height: codeBlockHeight }} className="w-1/5 p-4 ml-12">
          <div className="flex class">
            <h1 className="text-2xl font-bold">Receipt Preview</h1>
            {html && (
              <div className="relative inline-block ml-6">
                <div className="mb-4">
                  <button
                    onClick={() => setShowLanguageSelector(!showLanguageSelector)}
                    type="button"
                    className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50"
                  >
                    Language ({language})
                    <svg
                      className="w-5 h-5 ml-2"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M3 5h12M9 3v2m1.048 9.5A18.022 18.022 0 016.412 9m6.088 9h7M11 21l5-10 5 10M12.751 5C11.783 10.77 8.07 15.61 3 18.129"></path>
                    </svg>
                  </button>
                </div>
                {showLanguageSelector && (
                  <div
                    className="absolute right-0 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu"
                  >
                    <div className="py-1">
                      <button onClick={() => languageSelect("en")} className="block px-4 py-2 text-sm text-gray-700">
                        🏴󠁧󠁢󠁥󠁮󠁧󠁿 English
                      </button>
                      <button onClick={() => languageSelect("cy")} className="block px-4 py-2 text-sm text-gray-700">
                        🏴󠁧󠁢󠁷󠁬󠁳󠁿 Welsh
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          {html && <div className="w-11/12 h-auto p-8 bg-white shadow-2xl">{htmr(html)}</div>}
        </div>
      </div>
    </Authenticated>
  );
};

export default ReceiptViewer;
